
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import Head from "next/head";
import "../styles/globals.css";
import "../store";
import { pageview, gaEvent } from "../lib/ga";
import dynamic from "next/dynamic";
// import { websocket } from "../services/websocket";
// import { signInStatus } from "../ducks/signIn";
import { setLoadingBar } from "../ducks/siteData";
import { setBoardSize, setSetting } from "../ducks/settings";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { Provider } from "hooks-for-redux";
import { ErrorBoundary } from "react-error-boundary";
// import {
//   ERROR_BOUNDRY,
//   sendPingData,
//   setError,
//   setErrorMsg,
// } from "../ducks/ping";
// import Navigation from "../navigation/Navigation";
const Navigation = dynamic(() => import("../navigation/Navigation"));
const Footer = dynamic(() => import("../navigation/Footer"));
const Popup = dynamic(() => import("../components/Popup"));
// import { GrowthBookProvider } from "@growthbook/growthbook-react";
// import { growthbook } from "../util/growthbook";
import { idleLoadScripts } from "../idleLoadScripts";
declare global {
    interface Window {
        gtag: any;
        googlefc: any;
        adsbygoogle: any;
        AppleID: any;
        fbAsyncInit: any;
        Stripe: any;
        __cmp: any;
        setCookie: any;
        __tcfapi: any;
        google: any;
        chesshotel: any;
        chesshotelPuzzle: any;
        fusetag: any;
        FB: any;
        dataLayer: any;
        google_optimize: any;
        ramp: any;
        yaContextCb: any;
        Ya: any;
        _pwGA4PageviewId: any;
        workbox: any;
        userHasInteracted: boolean;
        webkitAudioContext: any;
    }
}
function initSettings() {
    try {
        const chessSet = localStorage.getItem("chessSet");
        if (chessSet) {
            setSetting({ name: "chessSet", val: chessSet });
        }
    }
    catch (e) {
        console.log(e);
    }
}
function ErrorFallback({ error, resetErrorBoundary }) {
    return (<Popup onClose={null} title="Something went wrong">
      <div className="flex flex-col items-center justify-center w-full pt-4 mt-4">
        <div className="mb-6">{error.message}</div>
        <button className="btn btn-base btn-blue" onClick={resetErrorBoundary}>
          Try again
        </button>
      </div>
    </Popup>);
}
const myErrorHandler = (error: Error, info: {
    componentStack: string;
}) => {
    import("../ducks/ping").then((module) => {
        const { setError, setErrorMsg, ERROR_BOUNDRY, sendPingData } = module;
        setError(ERROR_BOUNDRY);
        setErrorMsg({
            errorMsg: "version9 " + error.message,
            componentStack: info.componentStack,
        });
        sendPingData();
    });
};
function MyApp({ Component, pageProps, userData }) {
    const router = useRouter();
    const currentPath = router.pathname;
    const gamePath = currentPath.startsWith("/game") ||
        currentPath.startsWith("/daily/game") ||
        currentPath.startsWith("/analysis");
    const iframePath = currentPath.startsWith("/iframe");
    useEffect(() => {
        if (typeof Intl === "undefined" ||
            typeof Intl.PluralRules === "undefined") {
            gaEvent({
                action: "Intl.PluralRules polyfill loaded",
                category: "polyfill",
                label: "Intl.PluralRules",
            });
        }
        // initLobbyChosenGame();
        initSettings();
        setBoardSize();
        idleLoadScripts();
        function onUserInteraction() {
            window.userHasInteracted = true;
            document.removeEventListener("click", onUserInteraction);
            document.removeEventListener("mousedown", onUserInteraction);
            document.removeEventListener("touchstart", onUserInteraction);
            // Dispatch custom event for deferred operations
            window.dispatchEvent(new Event("userHasInteracted"));
        }
        document.addEventListener("touchstart", onUserInteraction);
        document.addEventListener("mousedown", onUserInteraction);
        document.addEventListener("click", onUserInteraction);
        return () => {
            document.removeEventListener("click", onUserInteraction);
            document.removeEventListener("mousedown", onUserInteraction);
            document.removeEventListener("touchstart", onUserInteraction);
        };
    }, []);
    useEffect(() => {
        const handleStart = (url: string) => {
            setLoadingBar(true);
        };
        const handleStop = (url: string) => {
            setLoadingBar(false);
            pageview(url);
            // growthbook.setURL(window.location.href);
        };
        const handleError = () => {
            setLoadingBar(false);
        };
        router.events.on("routeChangeStart", handleStart);
        router.events.on("routeChangeComplete", handleStop);
        router.events.on("routeChangeError", handleError);
        return () => {
            router.events.off("routeChangeStart", handleStart);
            router.events.off("routeChangeComplete", handleStop);
            router.events.off("routeChangeError", handleError);
        };
    }, [router.events]);
    return (<>
      {/*
        //@ts-ignore */}
      <Provider>
        <Head>
          <meta name="viewport" content="viewport-fit=cover, width=device-width, height=device-height, initial-scale=1, shrink-to-fit=no, maximum-scale=1, user-scalable=no"/>
        </Head>
        {!iframePath && <Navigation />}
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {
            window.location.reload();
        }} onError={myErrorHandler}>
          {/* <GrowthBookProvider growthbook={growthbook}> */}
          <Component {...pageProps}/>
          {/* </GrowthBookProvider> */}
        </ErrorBoundary>
        {!gamePath && !iframePath && <Footer />}
      </Provider>
    </>);
}
const __Next_Translate__Page__19610a96a0c__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__19610a96a0c__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  
import { initWebPush } from "./ducks/webPush";
import { loadAds } from "./loadAds";

export function idleLoadScripts() {
  // if ("requestIdleCallback" in window) {
  //   requestIdleCallback(() => {
  //     idleLoad();
  //   });
  // } else {
  setTimeout(() => idleLoad(), 2000);
  // }
}

let hasRun = false;
function idleLoad() {
  if (hasRun) return;
  hasRun = true;

  if (process.env.NODE_ENV == "production") {
    consentListener();
    loadAds();
    // growthbookExperiments();
    initWebPush();
  }
}

// function loadGA() {
//   // 1. Add Google Analytics script
//   const gaScript = document.createElement("script");
//   gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
//   gaScript.async = true;
//   document.head.appendChild(gaScript);

//   // 2. Add GA and Ramp initialization
//   gaScript.onload = () => {
//     window.ramp = window.ramp || {};
//     window.ramp.que = window.ramp.que || [];
//     window.ramp.passiveMode = true;

//     window._pwGA4PageviewId = Date.now().toString();
//     window.dataLayer = window.dataLayer || [];

//     window.gtag = function (...args: any[]) {
//       window.dataLayer.push(args);
//     };

//     window.gtag("js", new Date());

//     window.gtag("config", RAMP_GA4, { send_page_view: false });

//     window.gtag("event", "ramp_js", {
//       send_to: RAMP_GA4,
//       pageview_id: window._pwGA4PageviewId,
//     });

//     window.gtag("config", GA_TRACKING_ID, {
//       page_path: window.location.pathname,
//     });

//     if (process.env.NODE_ENV == "production") {
//       loadAds();
//     }

//   };
// }

// async function growthbookExperiments() {
//   await growthbook.loadFeatures({ autoRefresh: true });

//   //Check if first visit
//   // const visitedBefore = localStorage.getItem("chosenTime");

//   // if (!visitedBefore) {
//   //   if (growthbook.isOn("staunty")) {
//   //     localStorage.setItem("chessSet", ChessSets.STAUNTY);
//   //     setSetting({ name: "chessSet", val: ChessSets.STAUNTY });
//   //   }
//   // }
// }

export function consentListener() {
  window.googlefc = window.googlefc || {};
  window.googlefc.callbackQueue = window.googlefc.callbackQueue || [];
  window.googlefc.callbackQueue.push({
    CONSENT_DATA_READY: () =>
      window.__tcfapi("addEventListener", 2.2, (data, success) => {
        if (success) {
          updateGAConsent(data.purpose.consents);
        }
      }),
  });
}

// Function to update Google Analytics consent settings
function updateGAConsent(purposeConsents) {
  const consentData = {
    analytics_storage: purposeConsents[1] ? "granted" : "denied",
    ad_storage: purposeConsents[2] ? "granted" : "denied",
    ad_personalization: purposeConsents[4] ? "granted" : "denied",
    ad_user_data: purposeConsents[7] ? "granted" : "denied",
  };
  window.gtag("consent", "update", consentData);
}

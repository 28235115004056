// No users yet:
//bn - Bengali - Bangladesh
//ca - Catalan
//kn - Kanada - 1 pers
//mr - Marathi - 0 pers
//ur - Urdu - 0 pers
//gu - Gujarati - 0 pers
//gl - Galician - 0 pers

module.exports = {
  defaultNS: "common",
  pages: {
    "/": ["home"],
    "/challenge/[slug]": ["game"],
    "/friends": ["lobby"],
    "/lobby": ["lobby"],
    "rgx:/leagues": ["game"],
    "rgx:/players": ["common"],
    "rgx:/puzzle": ["home"],
    "rgx:/settings": ["auth"],
    "rgx:/register": ["auth"],
    "rgx:/signIn": ["auth"],
    "rgx:/analysis": ["analysis"],
    "rgx:/profile": ["auth"],
    "rgx:/game-review": ["gameReview"],
    "rgx:/analysis-board": ["gameReview"],
    "*": ["common"],
  },
  locales: [
    "en",
    "fr",
    "sv",
    "pt",
    "ru",
    "it",
    "es",
    "de",
    "af",
    "ar",
    "az",
    "be",
    "bg",
    "bn",
    "bs",
    // "ca",
    "cs",
    "da",
    "el",
    "et",
    "fa",
    "fi",
    // "gl",
    // "gu",
    "he",
    "hi",
    "hr",
    "hu",
    "hy",
    "id",
    "is",
    "ja",
    "ka",
    "kk",
    // "kn",
    "ko",
    "lt",
    "lv",
    "ml",
    "mn",
    // "mr",
    "nl",
    "nb",
    "pl",
    "ps",
    "pt-PT",
    "ro",
    "sk",
    "sl",
    "sr",
    "sw",
    "th",
    "tk",
    "tr",
    "uk",
    // "ur",
    "uz",
    "vi",
    "zh-CN",
    "zh-TW",
  ],
  defaultLocale: "en",
  // loader: true,
  // loadLocaleFrom: (lang, ns) => {
  //   return import(`./locales/${lang}/${ns}.json`).then((m) => m.default);
  // },
};

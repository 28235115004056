// import { growthbook } from "../../util/growthbook";
export async function gaUser(userId: any) {
  gaEvent({
    action: "user-id available",
    category: "authentication",
    label: userId,
  });
  window.gtag("config", GA_TRACKING_ID, {
    user_id: userId,
  });
  // window.gtag("get", GA_TRACKING_ID, "client_id", (client_id) => {
  //   growthbook.setAttributes({
  //     anonimous_id: client_id,
  //     loggedIn,
  //     lang,
  //   });
  // });
}

// function whenGtagReady(): Promise<void> {
//   return new Promise((resolve) => {
//     if (typeof window.gtag === "function") {
//       resolve();
//     } else {
//       const interval = setInterval(() => {
//         if (typeof window.gtag === "function") {
//           clearInterval(interval);
//           resolve();
//         }
//       }, 100);
//     }
//   });
// }

export const GA_TRACKING_ID = "G-4LM0JEPSS9"; // "UA-1683666-1"; //
export const RAMP_GA4 = "G-R76M8JQXNZ";

export async function pageview(url) {
  window.gtag("config", GA_TRACKING_ID, {
    page_path: url,
  });
}

export async function gaEvent({ action, category, label = null }) {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
  });
}

// export function consentCallback() {
//   // Initialize googlefc & it's queue before pushing the event listener for interaction

//   window.googlefc = window.googlefc || {};
//   window.googlefc.callbackQueue = window.googlefc.callbackQueue || [];
//   window.googlefc.callbackQueue.push({
//     CONSENT_DATA_READY: () =>
//       window.__tcfapi("addEventListener", 2.2, (data, success) => {
//         if (success) {
//           console.log("!!!data", data);

//           // Consent has been given

//           if (data.eventStatus === "tcloaded") {
//             // Load GA4 & GTM scripts here
//           }
//         }
//       }),
//   });
// }
